// Here you can add other styles
.customBootstrapTable {
  td {
    padding: 0.3rem;
  }
}

#emoji-close-div {
  display: none !important;
}

.collapsed-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  transition: all 0.3s;
}

.collapsedArrowHide {
  transform: rotate(0);
}

.collapsedArrowShow {
  transform: rotate(0.25turn);
}
